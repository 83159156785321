import useApiHelpers from '@/composables/api/helpers';
import { useTrackingStore } from '@/stores/tracking/trackingStore';

const { getRequest } = useApiHelpers();

export default function useOrderEndpoints() {
  const getFiats = async (orderType = 'buy') => getRequest(`/fiats/${orderType}`);

  const getCoins = async (orderType = 'buy') => getRequest(`/coins/${orderType}`);

  const getOrderSummary = async (orderId) => getRequest(`/status/${orderId}`);

  const initOrder = async () => getRequest('/init');

  const getOrderId = (onlyRegisteredOrder = true) => new Promise((resolve, reject) => {
    // Default onlyRegisteredOrder=true is used for the usecase when orderId should be returned
    // only if the order is registered in Centry
      getRequest('/orderId')
      .then((response) => {
        const { orderId = null, isRegistered = false } = response.data;
        if (orderId) {
          const trackingStore = useTrackingStore();
          trackingStore.recordOrderKey(orderId);
        }
        /**
         * If the order is registered => return orderId
         * If the order is not registered => and onlyRegisteredOrder is true => return null
         */
        const id = !onlyRegisteredOrder || isRegistered ? orderId : null;
        resolve(id);
      })
      .catch((err) => {
        reject(err);
      });
    });

  return {
    getFiats,
    getCoins,
    initOrder,
    getOrderSummary,
    getOrderId,
  };
}
