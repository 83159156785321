/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import useCustomerActions from '@/composables/api/useCustomerActions';
import {
 trackCustomer, trackOrderKey, trackAction, trackCustomProperty,
} from '@/composables/tracking/useTracking';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';

export const useTrackingStore = defineStore({
  id: 'tracking',
  state: () => ({
    orderKey: null,
    customerKey: null,
    actions: [
    //   {
    //   actionName: 'ORDER_CREATION',
    //   payload: {currency: 'USD'},
    // }
  ], // array of objects { actionName: string, payload: object }
  }),
  actions: {
    async recordCustomer() {
      if (this.customerKey) {
        return;
      }

      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        this.customerKey = await useCustomerActions().fetchCustomerId();
        trackCustomer(this.customerKey);
      } catch (error) { }
    },
    recordOrderKey(orderKey) {
      if (!orderKey || this.orderKey) {
        return;
      }

      this.orderKey = orderKey;
      trackOrderKey(this.orderKey);
    },
    recordAction(actionName, payload = {}) {
      if (!actionName || typeof actionName !== 'string') {
        return;
      }

      const capitalizedActionName = actionName.toUpperCase();

      this.actions.push({ actionName: capitalizedActionName, payload });
      trackAction(capitalizedActionName, payload);
    },
    recordOrderFormSubmission() {
      let payload = {};
      const formPayload = useOrderFormStore().getActiveStore.getFormPayload;

      if (formPayload) {
        payload = {
          fiatAmount: formPayload.fiatAmount,
          fiatType: formPayload.fiatType,
          coinAmount: formPayload.coinAmount,
          coinType: formPayload.coinType,
          blockchain: formPayload.blockchain,
          payment: formPayload.payment,
          paymentType: useOrderFormStore().getPaymentObject?.pspTypeCode,
          orderType: useOrderFormStore().getOrderType,
        };
        this.recordAction('ORDER FORM SUBMITTED', payload);
      }
    },

    recordPaymentAttemptFailed() {
      this.recordAction('PAYMENT ATTEMPT FAILED', { paymentType: useOrderFormStore().getPaymentObject?.pspTypeCode });
    },

    recordPaymentFailed() {
      this.recordAction('PAYMENT FAILED', { paymentType: useOrderFormStore().getPaymentObject?.pspTypeCode });
    },

    recordPaymentSuccess() {
      this.recordAction('PAYMENT SUCCESS', { paymentType: useOrderFormStore().getPaymentObject?.pspTypeCode });
    },

    recordError(errorCode, errorMessage, traceId) {
      this.recordAction('ERROR RENDERED', {
        code: errorCode,
        message: errorMessage,
        traceId,
      });
      if (traceId) {
        trackCustomProperty('errorTraceId', traceId);
      }
    },
  },
  getters: {
    getActions: (state) => state.actions,
    getCustomerKey: (state) => state.customerKey,
  },
});
