/* eslint-disable no-param-reassign */
import { useGlobalStore } from '@/stores/globalStore';
import useApiHelpers from './helpers';

const { getRequest } = useApiHelpers();

export default function useCustomerActions() {
  const globalStore = useGlobalStore();

  const fetchCustomerId = async () => {
    if (globalStore.global.customerId) {
      return globalStore.global.customerId;
    }

    const res = await getRequest('/customerId');
    globalStore.setCustomerId(res.data.customerId);
    return res.data.customerId;
  };

  const fetchCustomerKycSharingInfo = async () => {
    try {
      const res = await getRequest('/getKycSharingPersonalDetails');
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCustomerAccountInfo = async () => {
    const res = await getRequest('/getCustomerAccountInfo');
    return res.data;
  };

  const fetchCustomerDetails = async () => {
    try {
      const res = await getRequest('/getCustomerDetails');
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const fillForm = (personalDetailsFromApi, form, store) => {
    if (!personalDetailsFromApi || !form || !store) return;
    const values = Object.entries(personalDetailsFromApi);
    let dob;

    values.forEach(([key, value]) => {
      if (!store.form[key]) {
        return;
      }
      if (key === 'country') {
        const countryName = globalStore.getCountryName(value);
        store.form[key].value = countryName;
        form.value[key] = countryName;
        return;
      }

      // if (key === 'dob') {
      //   dob = value;
      // }

      store.form[key].value = value;
      form.value[key] = value;
    });

    // if (dob
    //   && Object.hasOwnProperty.call(personalDetailsFromApi, 'country')
    //   && personalDetailsFromApi.country && personalDetailsFromApi.country === 'US') {
    //     const [day, month, year] = dob.split('-');
    //     const newDob = `${month}-${day}-${year}`;
    //     form.value.dob = newDob;
    // }
  };

  return {
    fetchCustomerId,
    fetchCustomerAccountInfo,
    fetchCustomerDetails,
    fetchCustomerKycSharingInfo,
    fillForm,
  };
}
