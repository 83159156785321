import {
  setGlobalContextProperty,
  setUser as setDataDogUser,
  addFeatureFlagEvaluation,
  addAction,
} from './useDataDogTracking';
import { trackClarityTag, trackClarityIdentity, trackClarityEvent } from './useClarityTracking';


export const trackTTS = (key, value) => {};

/**
 * Tracks a customer in datadog & registers customer id in clarity
 * @param {string} customerId customer id
 * @returns
 */
export const trackCustomer = (customerId) => {
  if (!customerId) {
    return;
  }

  trackClarityIdentity(customerId);
  setDataDogUser({ id: customerId });
};

/**
 * Tracks an order key in datadog & registers orderKey tag in clarity
 * @param {string} orderKey order key
 * @returns
 */
export const trackOrderKey = (orderKey) => {

  if (!orderKey) {
    return;
  }

  setGlobalContextProperty('orderKey', orderKey);
  trackClarityTag('orderKey', orderKey);
};

/**
 * Tracks a custom property in datadog & registers a tag in clarity
 * @param {string} key key
 * @param {string} value value
 * @returns
 */
export const trackCustomProperty = (key, value) => {
  if (!key || !value) {
    return;
  }

  setGlobalContextProperty(key, value);
  trackClarityTag(key, value);
};

/**
 * Tracks a feature flag in datadog & registers adds a tag in clarity
 * @param {string} featureName feature name
 * @param {boolean} value feature value
 * @returns
 */
export const trackFeatureFlag = (featureName, value) => {
  if (!featureName || value === undefined) {
    return;
  }

  trackClarityTag(featureName, value);
  addFeatureFlagEvaluation(featureName, value);
};

/**
 * Tracks an action in datadog & registers an event in clarity
 * @param {string} actionName action name
 * @param {object} payload action payload
 * @returns
 */
export const trackAction = (actionName, payload = {}) => {
  if (!actionName) {
    return;
  }
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    trackClarityEvent(actionName.toString());
    addAction(actionName.toString(), payload);
  } catch (error) { }
};
