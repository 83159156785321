export const setGlobalContextProperty = (key, payload) => {
    window.DD_RUM?.setGlobalContextProperty(key, payload);
};

export const readGlobalContextProperty = (key) => window.DD_RUM?.getGlobalContext()?.[key];

export const removeGlobalContextProperty = (key) => {
    window.DD_RUM?.removeGlobalContextProperty(key);
};

export const setViewContextProperty = (key, value) => {
  window.DD_RUM?.setViewContextProperty(key, value);
};

export const setUser = (payload = {}) => {
  window.DD_RUM?.setUser(payload);
};

export const addAction = (actionName = '', payload = {}) => {
  window.DD_RUM?.addAction(actionName, payload);
};

export const addFeatureFlagEvaluation = (featureName = '', value = true) => {
  window.DD_RUM?.addFeatureFlagEvaluation(featureName, value);
};
