import { createRouter, createWebHistory } from 'vue-router';

import { useRouteStore } from '@/stores/tracking/routeStore';
import tracker from '@/composables/api/useTracker';
import useBeforeEach from '@/composables/router/useBeforeEach';
import handleDirectVisit from '@/router/middleware/handleDirectVisit';
import { useTrackingStore } from '@/stores/tracking/trackingStore';
import IdentityRoutes from './identity';
import NftRoutes from './nft';
import PrimaryRoutes from './primary';
import DebugRoutes from './debug';

const {
  handleSuccessModal,
  handleIdentityKycPathings,
  resetLoadingButtonState,
} = useBeforeEach();
const allRoutes = [...PrimaryRoutes, ...IdentityRoutes, ...NftRoutes];

if (import.meta.env.VITE_ENV !== 'production') {
  allRoutes.push(...DebugRoutes);
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: allRoutes,
});

router.beforeEach((to, from, next) => {
  const routeHistory = useRouteStore();
  routeHistory.push(to);
  handleSuccessModal(to, from, next);
  handleIdentityKycPathings(to, from, next);
  resetLoadingButtonState();
  next();
});

router.afterEach((to, from) => {
  if (to.name === from.name) { return; }
  if (to.name === 'order' && from.name === 'order-recovery') {
    handleDirectVisit();
  }
  resetLoadingButtonState();
  // if (to.name === from.name) { }
  // sendTrackerEvent();
});

export default router;
