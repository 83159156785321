/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { fetchCurrencyFromStorage } from '@/composables/helpers/countryCurrency';
import useBlockchainFilters from '@/composables/filters/useBlockchainFilters';
import { useBuyOrderFormStore } from '@/stores/forms/buyOrderFormStore';
import { useGlobalStore } from '@/stores/globalStore';
import useLocalStorage from '@/composables/storage/useLocalStorage';
import { useNFTOrderFormStore } from '@/stores/forms/nftOrderFormStore';
import usePaymentFilters from '@/composables/filters/usePaymentFilters';
import { useSellOrderFormStore } from '@/stores/forms/sellOrderFormStore';
import { trackClarityEvent, trackClarityTag } from '@/composables/tracking/useClarityTracking';

const { checkLocalStorage, bindLocalStorage } = useLocalStorage();
const { getActiveCoinData } = useBlockchainFilters();
const {
  filterPaymentProvider,
  filterPaymentDataByPaymentName,
  filterPaymentsByFiatAndCoin,
} = usePaymentFilters();

const settings = {
  mode: 'BUY', // to come from merch settings
  defaultCoinType: 'BTC',
  defaultBLockchain: 'BTC',
  defaultFiatType: fetchCurrencyFromStorage(),
  defaultFiatAmount: 100,
};

export const useOrderFormStore = defineStore({
  id: 'order',
  state: () => ({
    settings,
    form: {
      ...{
        mode: checkLocalStorage('mode', settings.mode),
      },
    },
    orderAccordionData: checkLocalStorage('orderAccordion', {
      fiatAmount: 100,
      fiatType: settings.defaultFiatType,
      coinType: settings.defaultCoinType,
      coinAmount: 0,
      networkFee: 0,
      gatewayFee: 0,
      receivedRate: 0,
      quoteValue: 0,
    }),
    merchApiOrderData: {},
    isAnExpressCheckoutOrder: false,
    isAnExpressCheckoutPaymentComplete: false,
  }),
  actions: {
    updateDefaultCoin(coinType) {
      if (coinType) {
        this.settings.defaultCoinType = coinType;
      }
    },
    updateOrderAccordion(key, value) {
      this.orderAccordionData[key] = value;
      bindLocalStorage('orderAccordion', this.orderAccordionData);
    },
    // currently modes can be BUY, SELL or NFT-BUY
    setActiveMode(mode) {
      this.form.mode = mode;
      bindLocalStorage('mode', this.form.mode);
    },
    setActiveModePaymentProvider(provider) {
      this.getActiveStore.setPaymentServiceProvider(provider);
    },
    setPspTypeCode(pspCode) {
      // Working of this function can be changed to include other parameters of the Payment
      this.getActiveStore.setPspTypeCode(pspCode);
    },
    setPspRequireMobile(value) {
      this.getActiveStore.form.payment.require_mobile = value;
    },
    setPaymentServiceProvider(value) {
      this.getActiveStore.setPaymentServiceProvider(value);
    },
    toggleBuySellMode() {
      const switchMode = this.form.mode === 'BUY' ? 'SELL' : 'BUY';
      trackClarityEvent('buy sell toggled');
      this.setActiveMode(switchMode);
    },
    setMerchApiData(data) {
      this.merchApiOrderData = data;
    },
    setExpressCheckoutEnabled(isEnabled) {
      trackClarityTag('isAnExpressCheckoutOrder', isEnabled);
      this.isAnExpressCheckoutOrder = isEnabled;
    },
    setExpressCheckoutPaymentComplete(completed) {
      this.isAnExpressCheckoutPaymentComplete = completed;
    },
    /*
      * Check if Apple Pay is available in browser and check if the payment method
      * is available via B2C at the same time check for null/undefined value on
      * order data payment method
    */

    async checkUserApplePaySession() {
      const globalStore = useGlobalStore();
      const isApplePayAvailable = () => !!window.ApplePaySession;

      // TODO: this will change to HE-5056's getPaymentByCode to pull
      // the specific payment's data only on a redirect

      if (this.getActiveStorePaymentData.length <= 0) {
        await globalStore.fetchPayments();
      }

      // this also checks for the User's apple pay session before filtering the payment out
      const isApplePayMethodPresent = this.merchApiOrderData?.payment
      && globalStore.getPayments.find(
        (paymentMethod) => paymentMethod.code === this.merchApiOrderData?.payment,
      );
      // const applePayPaymentProviders = ['WORLDPAYAPPLE', 'PRIMERAP'];
      // const orderPaymentTypeIsApplePay = () => this.merchApiOrderData?.paymentType
      // && applePayPaymentProviders.includes(
      //   this.merchApiOrderData.paymentType.toUpperCase(),
      // );

      return (
        !isApplePayAvailable()
        && !isApplePayMethodPresent
        && this.isBuyMode);
     },
  },
  getters: {
    isBuyMode() {
      return this.getActiveMode === 'BUY';
    },
    isSellMode() {
      return this.getActiveMode === 'SELL';
    },
    isNFTMode() {
      return this.getActiveMode === 'NFT-BUY';
    },
    getActiveStore() {
      const activeMode = this.getActiveMode;
      const buyStore = useBuyOrderFormStore();
      const sellStore = useSellOrderFormStore();
      const nftStore = useNFTOrderFormStore();

      switch (activeMode) {
        case 'BUY':
          return buyStore;
        case 'SELL':
          return sellStore;
        case 'NFT-BUY':
          return nftStore;
        default:
          return buyStore;
      }
    },

    getActiveStorePaymentData() {
      const globalStore = useGlobalStore();
      const paymentData = this.isBuyMode
        ? globalStore.global.payments
        : this.isSellMode
        ? globalStore.global.payouts
        : globalStore.global.nftPayments;

      return paymentData;
    },
    getActiveMode() {
      this.setActiveMode(this.form.mode);
      return this.form.mode;
    },
    getFiatType() {
      if (!this.getActiveStore.form?.fiatType) {
        return null;
      }
      return this.getActiveStore.form.fiatType.value;
    },
    getCoinType() {
      if (!this.getActiveStore.form?.coinType) {
        return null;
      }
      return this.getActiveStore.form.coinType.value;
    },
    getOrderType(store) {
      if (!store.orderAccordionData?.coinType) {
        return null;
      }
      return store.orderAccordionData.orderType;
    },

    getRestrictedCountriesByActiveCoin() {
      const { coinType, data } = this.getActiveStore.coinData;
      const coinData = getActiveCoinData(data, coinType);

      if (!coinData) return [];
      return coinData?.restrictions?.country;
    },

    getActivePaymentByMode() {
      return this.getActiveStore.form.payment.value;
    },
    getPaymentProvider() {
      return filterPaymentProvider(
        this.getActiveStorePaymentData,
        this.getActivePaymentByMode,
      );
    },

    filterPaymentDataByActivePaymentName() {
      return filterPaymentDataByPaymentName(
        this.getActiveStorePaymentData,
        this.getActivePaymentByMode,
      )[0];
    },

    filterPaymentsByFiatAndCoin() {
      const activeFiat = this.getActiveStore.form.fiatType.value;
      const activeCoin = this.getActiveStore.form.coinType.value;

      return filterPaymentsByFiatAndCoin(
        activeFiat,
        activeCoin,
        this.getActiveStorePaymentData,
      );
    },

    getPaymentObject() {
      if (!this.getActiveStore.form?.payment) {
        return null;
      }
      return this.getActiveStore.form.payment;
    },

    isOrderFromMerchantApi() {
      const isFromMapi = !!Object.getOwnPropertyDescriptor(
        this.merchApiOrderData,
        'orderId',
      );
      if (isFromMapi) {
        trackClarityEvent('Merchant API');
      }
      return isFromMapi;
    },

    getMerchantApiData() {
      return this.merchApiOrderData;
    },

    canSkipOrderPageFromApiData() {
      const canSkip = this.merchApiOrderData?.redirectTo
      && this.merchApiOrderData.redirectTo !== 'order';

      if (canSkip) {
        trackClarityEvent('Skip Order Page');
      }

      return canSkip;
    },

  },
});
